/*! My very own Bootstrap 4 Theme
 *
 *  Built on top of Bootstrap 4 (https://getbootstrap.com)
 *  Built with the Bootstrap Theme Kit by HackerThemes (https://hackerthemes.com)
 */
@import 'custom-variables';
@import '../node_modules/bootstrap/scss/bootstrap';
@import '../node_modules/select2-bootstrap4-theme/src/select2-bootstrap4.scss';
@import 'custom-styles';

