// Overwrite Bootstrap's variables here
// You can find them in node_modules/bootstrap/scss/_variables.scss
// Copy the variables you need into this file, don't modify files under node_modules/

// Some example variables that you can uncomment:

// Enabling shadows and gradients
//$enable-shadows: true;
//$enable-gradients: true;
//$form-group-margin-bottom: 2px;
$table-cell-padding: .3rem;

$font-size-base:              .8rem;
//$font-size-base:              1rem;
$font-size-lg:                ($font-size-base * 1.25);
$font-size-sm:                ($font-size-base * .875);

$navbar-dark-color: #ffffff;

@media (min-width: 768px) {
  .form-group.row > .col-form-label {
    text-align: right;
  }
}

// Changing the body background and text
//$body-bg: #d3e9eb;
//$body-color: #151417;

// Changing the border radius of buttons
//$border-radius: 15px;

// Enabling shadows and gradients
$enable-shadows: true;
$enable-gradients: true;

// Changing the body background and text
$body-bg: #ffffff;
$body-color: #151417;

// Changing the border radius of buttons
//$border-radius: 15px;

// Changing the theme colors
$primary: #3030b5;
$secondary: #5d5d5f;
//$secondary: #436296;
//$success: #2bc550;
$info: #4c9bc0;
//$info: #00ff00;
//$warning: #ef8143;
//$danger: #ff293a;
//$light: #dfe6ee;
//$dark: #0f1319;


// Changing the theme colors
//$primary: #202f41;
//$secondary: #436296;
//$success: #2bc550;
//$info: #495dff;
//$warning: #ef8143;
//$danger: #ff293a;
//$light: #dfe6ee;
//$dark: #0f1319;


// Adding (!) an additional theme color (ex. classes btn-cool, bg-cool)
//$theme-colors: (
//  "cool": #4d3fa3
//);



// Select 2

//$s2bs-padding-base-horizontal: 0;
//$s2bs-padding-large-horizontal: 0;
$s2bs-padding-small-horizontal: 0;
$s2bs-padding-base-vertical: 0;
//$s2bs-padding-large-vertical: 0;
$s2bs-padding-small-vertical: 0;

//$s2bs-padding-base-horizontal: $custom-select-padding-y !default;
//$s2bs-padding-large-horizontal: $input-btn-padding-y-lg !default;
//$s2bs-padding-small-horizontal: $input-btn-padding-y-sm !default;
//$s2bs-padding-base-vertical: $custom-select-padding-x !default;
//$s2bs-padding-large-vertical: $input-btn-padding-x-lg !default;
//$s2bs-padding-small-vertical: $input-btn-padding-x-sm !default;


